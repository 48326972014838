import React, { useState } from "react";
import Layout from "../layouts/layout";

import bottle from "../images/keto/Keto.png";
import bottle3 from "../images/keto/Keto3.png";
import sara from "../images/diabetamina/especialista_sara.png";
import testimonialOne from "../images/vane.jpeg";
import testimonialTwo from "../images/carlos.jpeg";
import Modal from "../components/Modal";
import Form from "../components/keto/DynamicForm";
import CTASection from "../components/keto/CTASection";
import Tabs from "../components/Tabs";

// markup
const Campaign = () => {
	const modalRef = React.useRef();
	const [formSent, setFormSent] = useState(false);

	function formJustSent() {
		setFormSent(true);
	}

	const quantity_available = Math.floor(Math.random() * (20 - 3) + 3),
		team_id = 1;

	return (
		<div>
			<Layout title="Almalu Keto Formula Quema Grasa Rápido Sin Dietas Ni Ejercicio">
				<div className="px-2 pt-32 lg:pt-16 lg:px-0">
					<div className="lg:bg-main-keto bg-center lg:h-fit">
						<div className="flex flex-col items-center justify-center mt-6 sm:mt-0 px-4 lg:px-24 max-w-6xl">
							<h2 className="text-5xl lg:text-7xl font-semibold text-center pb-4 lg:pb-8 lg:pt-6 text-navy-500 uppercase">
								¡Convierte grasa en energía!
							</h2>
							<h1 className="text-2xl lg:text-3xl font-semibold uppercase text-black">
								Quema grasa en una manera rápida y segura
							</h1>
							<h3 className="text-xl lg:text-3xl font-extrabold text-yellow-500 bg-black p-1 mt-2">
								Sin Dietas ni Ejercicio
							</h3>
							<p className="text-yellow-500 bg-black p-1 text-lg">
								Almalu Keto Formula es una poderosa fórmula que activa la
								ketosis para bajar de peso.
							</p>
							<ul className="text-lg lg:text-2xl text-center font-bold my-6">
								<li>
									<span class="text-navy-500 text-3xl">•</span> Quema grasa
									transformándola en energía y bloquea los carbohidratos
								</li>
								<li>
									<span class="text-navy-500 text-3xl">•</span> Aumenta la
									energía en forma natural
								</li>
								<li>
									<span class="text-navy-500 text-3xl">•</span> Mejora tu salud,
									tu estado físico y tu estado de ánimo
								</li>
							</ul>
						</div>

						<div className="md:max-w-6xl px-4 md:px-40">
							<div className="grid grid-cols-1 lg:grid-cols-3 justify-center">
								<img
									src={bottle}
									className="h-48 w-auto lg:w-72 lg:h-80 lg:mt-12 mx-auto"
									alt="Almalu Keto Formula"
								/>

								<div className="lg:col-span-2 lg:mb-12">
									<Form
										product="Almalu Keto Formula"
										team_id={team_id}
										onStored={formJustSent}
										sent={formSent}
										type="standalone"
										quantity_available={quantity_available}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>

				<section className="mt-14 mb-2 lg:mb-10 bg-white-abstract">
					<div className="grid grid-cols-2 lg:grid-cols-3 items-center">
						<div className="col-span-2 max-w-4xl px-10 mx-auto sm:px-20 lg:px-32 lg:px-16 text-center">
							<h2 className="text-pink-900 uppercase font-bold text-4xl lg:text-6xl mb-1">
								Consulta gratuita
							</h2>
							<h4 className="text-red-600 uppercase font-semibold text-2xl lg:text-4xl">
								Antes de la compra y sin compromisos
							</h4>
							<p className="font-light text-xl lg:text-3xl pt-4">
								Diariamente nuestros especialistas ayudan a más de 300
								consumidores de Keto Formula a guiarlos en el proceso total de
								su tratamiento de pérdida de peso. Aumentamos la cantidad de
								especialistas calificados y certificados para atender cualquier
								tipo de consulta antes de la compra.
							</p>
							<button
								onClick={() => modalRef.current.openModal()}
								className="bg-yellow-600 hover:bg-yellow-400 py-2 px-3 rounded shadow mt-4 text-lg mb-4 lg:text-2xl font-semibold"
							>
								Obtener el descuento exclusivo para Costa Rica
							</button>
						</div>
						<img
							src={sara}
							className="w-2/3 hidden lg:block"
							alt="Especialista Sara"
						/>
					</div>
				</section>

				<section className="mb-8">
					<div className="items-center max-w-3xl px-10 mx-auto sm:px-20 md:px-32 lg:px-16">
						<h2 className="text-navy-500 uppercase text-center font-bold text-3xl mb-4">
							¿Cómo funciona Almalu Keto Formula?
						</h2>
						<p className="text-center">
							La ketosis es un proceso metabólico natural de tu cuerpo que te
							ayuda a quemar grasa eficientemente,{" "}
							<span className="font-semibold">transformándola en energía</span>{" "}
							para lograr un mejor rendimiento físico y mental mientras pierdes
							peso rápidamente.
						</p>
						<h2 className="text-gold-500 uppercase text-center font-bold text-3xl mb-4 mt-12">
							Qué nos diferencia del resto?
						</h2>
						<p className="text-center">
							Que es una formula única, combinando los mejores quemadores de
							grasa en forma natural, limpiando tu organismo de colesterol malo,
							además ayuda a controlar los trigliceridos y transformar la grasa
							en energía; sin efectos secundarios.
						</p>
					</div>
				</section>

				<section className="mt-14 mb-20 py-12 bg-white-abstract relative">
					<div className="items-center lg:max-w-6xl px-10 mx-auto sm:px-20 lg:px-32 lg:px-16">
						<h2 className="text-yellow-500 uppercase text-center font-bold text-3xl lg:text-3xl mb-4">
							Con Almalu Keto Formula es mucho más fácil
						</h2>
						<img
							src={bottle3}
							className="h-48 mx-auto lg:mx-0 lg:h-80 mt-12 lg:absolute lg:top-20 lg:left-72"
							alt="Frasco keto"
						/>
						<ul className="list-none text-xl lg:text-2xl lg:pl-72">
							<li className="before:content-['✅'] pt-4">
								Acreditado por el ministerio de salud
							</li>
							<li className="before:content-['✅'] pt-4">
								Sin efectos secundarios
							</li>
							<li className="before:content-['✅'] pt-4">
								Ayuda a transformar grasa en energía
							</li>
							<li className="before:content-['✅'] pt-4">
								Mejora la salud activando la ketosis en el organismo
							</li>
							<li className="before:content-['✅'] pt-4">
								Asesoramiento permanente de nuestros especialistas
							</li>
							<li className="before:content-['✅'] pt-4">
								100% natural, sin aditivos artificiales
							</li>
						</ul>
					</div>
				</section>

				<section className="py-20 lg:py-24 bg-gold-400/80">
					<div className="max-w-7xl mx-auto">
						<div className="grid grid-cols-1 md:grid-cols-2 gap-x-24 gap-y-6">
							<div>
								<h4 className="font-bold text-3xl uppercase text-center">
									Cómo utilizar{" "}
									<span className="text-navy-500">Almalu Keto Formula</span>{" "}
									para quemar grasa
								</h4>
								<Tabs
									sections={{
										"Paso 1": {
											title: "Tomar Almalu Keto Formula diariamente",
											description:
												"<b>Almalu Keto Formula</b> te ayudará a entrar en un estado de ketosis el cual es clave para ayudarte a quemar grasa y utilizarla como energía. Es necesario tomar una cápsula 20 minutos antes de cada comida (2 veces al día) sin interrupciones.",
										},
										"Paso 2": {
											title:
												"Estado de Ketosis activado. Pierde grasa aceleradamente",
											description:
												"Con la ketosis activada, <b>Almalu Keto Formula</b> te ayudará a liberar grasa almacenada innecesaria de manera acelerada, convirtiéndola en energía que tu cuerpo empezará a aprovechar, además bloqueando los carbohidratos y limpiando el colesterol malo del cuerpo.",
										},
										"Paso 3": {
											title: "Transforma tu cuerpo y mantén tu estado ideal",
											description:
												"Cuando hayas alcanzado tus objetivos, continúa tomando <b>Almalu Keto Formula</b> durante 3-5 meses para mantener los resultados obtenidos y evitar un efecto rebote, manteniendo tu cuerpo delgado y sano.",
										},
									}}
								/>
							</div>
							<div>
								<h1 className="font-bold text-3xl text-navy-500 uppercase text-center">
									Activa la ketosis en tu cuerpo
								</h1>
								<h3 className="text-orange-500 uppercase font-bold text-2xl text-center my-5">
									Utiliza grasa como energía <br /> potencia tu metabolismo
								</h3>
								<p className="leading-relaxed text-center md:text-left px-4">
									Cuando tu cuerpo realiza la ketosis, la grasa también se
									convierte en cetonas en el hígado, que puede suministrar
									energía para tu cerebro. Almalu Keto Formula te ayuda a
									alcanzar el proceso de ketosis fácilmente, superando los
									efectos negativos inmersos en una dieta ketogénica.
									<br />
									<br />
									La ketosis es muy difícil de alcanzar de manera natural. Por
									eso, Almalu Keto Formula te ayudará a entrar rápidamente en
									este proceso, logrando a su vez un estado activo y rápido de
									pérdida de peso.
									<br />
									<br />
									¡Alcanza tus objetivos y ama la forma en la que te sientes!
								</p>
							</div>
						</div>
					</div>
				</section>

				<section>
					<div className="bg-[url('./images/ketobg.jpg')] bg-cover bg-center w-full h-fit">
						<div className="flex flex-col pl-4 sm:pl-12 md:pl-8 lg:pl-24 xl:pl-40 py-28">
							<div className="text-white font-semibold text-center w-56 md:w-96 lg:w-1/2">
								<h2 className="text-3xl lg:text-4xl uppercase">
									Todos los beneficios de la ketosis
								</h2>
								<h3 className="text-2xl lg:text-3xl pt-5">
									¡Sin dietas ni ejercicio!
								</h3>
							</div>
						</div>
					</div>
				</section>

				<section className="max-w-6xl mx-auto pt-12">
					<h1 className="text-4xl font-bold uppercase text-center">
						<span className="text-gold-500">Beneficios</span> de{" "}
						<span className="text-navy-500">Almalu Keto Formula</span>
					</h1>
					<div className="grid grid-cols-1 md:grid-cols-2 justify-center justify-items-center mt-10 gap-x-8 gap-y-10 mx-4">
						<div className="hidden md:block"></div>
						<div className="h-fit shadow-md shadow-gray-600/50 justify-self-center">
							<div className="py-3 bg-blue-800 text-white font-semibold text-xl">
								<h3 className="flex place-content-center text-center">
									Estimula la pérdida de peso
								</h3>
							</div>
							<p className="p-4">
								Almalu Keto Formula ayuda a perder peso, quemar grasa en tu
								organismo, transformándola en energía eficientemente para que tu
								cuerpo pueda obtener energía de grasa en lugar de carbohidratos,
								lo que puede acelerar el proceso de pérdida de peso. Ya que la
								ketosis es muy difícil de lograr y tarda un par de semanas,
								Almalu Keto Formula te ayuda a alcanzar el estado de ketosis más
								rápido y de forma segura, logrando así quemar grasa y perder
								peso en el proceso.
							</p>
						</div>
						<div className="h-fit shadow-md shadow-gray-600/50">
							<div className="py-3 bg-emerald-400 text-white font-semibold text-xl">
								<h3 className="flex place-content-center text-center">
									Utiliza grasa como energía
								</h3>
							</div>
							<p className="p-4">
								El objetivo principal de un proceso de ketosis es utilizar la
								grasa como combustible y producir energía, pero también ayudar a
								controlar el apetito, reducir la ansiedad y los antojos de
								azúcar.
							</p>
						</div>
						<div className="hidden md:block"></div>
						<div className="hidden md:block"></div>
						<div className="h-fit shadow-md shadow-gray-600/50">
							<div className="py-3 bg-yellow-400/90 text-white font-semibold text-xl">
								<h3 className="flex place-content-center text-center">
									Más beneficios para la salud
								</h3>
							</div>
							<p className="p-4">
								Almalu Keto Formula también puede ayudarte a disminuir el riesgo
								de enfermedades cardiovasculares, mejorar la calidad de la piel
								y reducir el acné, regular la presión arterial, mejorar el
								rendimiento atlético y cerebral, mejorando así la memoria, la
								concentración y reduciendo el estrés.
							</p>
						</div>
					</div>
					<div className="flex justify-center mt-16 mx-4">
						<div className="h-fit shadow-md shadow-gray-600/50">
							<div className="py-3 bg-rose-500 text-white font-semibold text-xl">
								<h3 className="flex place-content-center text-center">
									Alcanza tus objetivos con la ketosis
								</h3>
							</div>
							<p className="p-4">
								Almalu Keto Formula te ayuda a alcanzar el proceso de ketosis
								rápidamente, logrando todos los beneficios que ya conociste.
								<br />
								<span className="font-semibold">
									¡Dale a tu cuerpo energía y salud!
								</span>
							</p>
						</div>
					</div>
				</section>

				<section className="py-12 max-w-7xl mb-8 mx-auto">
					<h3 className="uppercase text-3xl font-bold text-gray-800 text-center pb-4">
						Comienza a transformar la grasa en energía y siéntete más sano y en
						forma - ¡Ordena ahora!
					</h3>
					<div className="shadow-gray-600 shadow-lg rounded-lg border-4 border-dashed border-gray-500 p-6">
						<CTASection
							onStored={formJustSent}
							sent={formSent}
							team_id={team_id}
							quantity_available={quantity_available}
							short={true}
						/>
					</div>
				</section>

				<section className="bg-gray-100 pt-20 pb-24 mt-12">
					<div className="max-w-4xl mx-auto">
						<div className="flex flex-col justify-center gap-8 mx-4 mb-8">
							<div className="grid grid-cols-1 md:grid-cols-2 bg-white">
								<div className="bg-[url('./images/vane.jpeg')] bg-center bg-cover bg-no-repeat hidden md:block"></div>
								<div className="block md:hidden">
									<img src={testimonialOne} alt="Vane" className="" />
								</div>
								<div className="py-5 pl-2">
									<div className="flex flex-col pr-8">
										<div className="relative pl-12">
											<svg
												className="absolute left-0 w-10 h-10 text-navy-500 fill-current"
												data-primary="indigo-500"
												xmlns="http://www.w3.org/2000/svg"
												viewBox="0 0 100 125"
												data-primary-reset="{}"
											>
												<path d="M30.7 42c0 6.1 12.6 7 12.6 22 0 11-7.9 19.2-18.9 19.2C12.7 83.1 5 72.6 5 61.5c0-19.2 18-44.6 29.2-44.6 2.8 0 7.9 2 7.9 5.4S30.7 31.6 30.7 42zM82.4 42c0 6.1 12.6 7 12.6 22 0 11-7.9 19.2-18.9 19.2-11.8 0-19.5-10.5-19.5-21.6 0-19.2 18-44.6 29.2-44.6 2.8 0 7.9 2 7.9 5.4S82.4 31.6 82.4 42z"></path>
											</svg>
											<p className="mt-2 text-gray-600 text-lg">
												Probé decenas de dietas que me recomendaban y productos
												para quemar grasa. Nada me funcionaba, me sentía
												estancada. Pero cuando conocí Almalu Keto Formula
												comencé a ver grandes resultados en poco tiempo. Pasé de
												pesar 73 kilos a 65 y estoy segura que sin este producto
												no lo hubiera logrado. Lo recomiendo bastante.
											</p>
										</div>

										<h3 className="pl-12 mt-3 font-medium leading-5 text-gray-800 text-lg">
											Vanessa M.
										</h3>
									</div>
								</div>
							</div>

							<div className="grid grid-cols-1 md:grid-cols-2 bg-white">
								<div className="bg-[url('./images/carlos.jpeg')] bg-center bg-cover bg-no-repeat hidden md:block"></div>
								<div className="block md:hidden">
									<img src={testimonialTwo} alt="Carlos" className="" />
								</div>
								<div className="py-5 pl-2">
									<div className="flex flex-col pr-8">
										<div className="relative pl-12">
											<svg
												className="absolute left-0 w-10 h-10 text-navy-500 fill-current"
												data-primary="indigo-500"
												xmlns="http://www.w3.org/2000/svg"
												viewBox="0 0 100 125"
												data-primary-reset="{}"
											>
												<path d="M30.7 42c0 6.1 12.6 7 12.6 22 0 11-7.9 19.2-18.9 19.2C12.7 83.1 5 72.6 5 61.5c0-19.2 18-44.6 29.2-44.6 2.8 0 7.9 2 7.9 5.4S30.7 31.6 30.7 42zM82.4 42c0 6.1 12.6 7 12.6 22 0 11-7.9 19.2-18.9 19.2-11.8 0-19.5-10.5-19.5-21.6 0-19.2 18-44.6 29.2-44.6 2.8 0 7.9 2 7.9 5.4S82.4 31.6 82.4 42z"></path>
											</svg>
											<p className="mt-2 text-gray-600 text-lg">
												No acostumbraba a hacer ejecicio ni tenía una dieta
												saludable. Mi estado físico me acomplejaba. Un familiar
												me recomendó Almalu Keto Formula ya que había tenido
												buenos resultados. Llevo casi un año tomándolo y el
												cambio que ha dado mi vida habla por sí solo. Perdía
												bastante peso de grasa acumulada, y tuve la suficiente
												energía y motivación para realizar ejercicio, ¡Me parece
												increíble como estoy ahora! Este producto funciona,
												funciona muy bien.
											</p>
										</div>

										<h3 className="pl-12 mt-3 font-medium leading-5 text-gray-800 text-lg">
											Carlos N.
										</h3>
									</div>
								</div>
							</div>
						</div>

						<h2 className="font-semibold text-4xl uppercase text-center mx-4">
							¡Al igual que ellos,{" "}
							<span className="text-gold-500">tú también puedes lograrlo</span>!
						</h2>
					</div>
				</section>

				<section className="pt-20 pb-12 max-w-6xl mx-auto px-3">
					<p className="text-center text-sm">
						Todos nuestros productos se fabrican en Costa Rica en instalaciones
						registradas, cumpliendo siempre con las Buenas Prácticas de
						Fabricación GMP (Good Manufacturing Practice). Prestamos atención en
						todos los pequeños detalles, proporcionando a nuestros clientes la
						mejor calidad con componentes 100% naturales y sin aditivos ni
						conservantes artificiales. Todas nuestras fórmulas han sido probadas
						y verificadas en laboratorios legales y de confianza para asegurar
						que obtendrás los mejores resultados.
						<br />
						<br />
						Aviso: Los productos y la información que se encuentran en este
						sitio no están destinados a reemplazar el tratamiento o consejo
						médico profesional. Estos productos no están destinados a
						diagnosticar, tratar, curar o prevenir ninguna enfermedad. Los
						resultados individuales pueden variar.
					</p>
				</section>

				<button
					className="fixed bottom-0 w-full h-12 bg-navy-600 hover:bg-navy-500 cursor-pointer transition-colors flex items-center justify-center text-white font-semibold text-lg uppercase"
					onClick={() => modalRef.current.openModal()}
				>
					<p>¡Haz clic aquí para hacer el pedido con el descuento!</p>
				</button>
			</Layout>
			<Modal ref={modalRef}>
				<CTASection
					onStored={formJustSent}
					sent={formSent}
					team_id={team_id}
					quantity_available={quantity_available}
					crm="vibe"
				/>
			</Modal>
		</div>
	);
};

export default Campaign;
